import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { UiLineSeparator } from '@uireact/separator';
import { UiBadge } from '@uireact/badge';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "beta-components-"
    }}>{`Beta Components 🐣`}</h1>
    <UiLineSeparator mdxType="UiLineSeparator" />
    <br />
    <p>{`A beta component is identified because the published major version is 0.x.x and it has the beta badge:`}</p>
    <UiBadge category="warning" mdxType="UiBadge">❗️ Beta</UiBadge>
    <h2 {...{
      "id": "what-this-means"
    }}>{`What this means?`}</h2>
    <p>{`This means that the component is just cooked, maybe with a few features still in progress and it hasn't been tested in production
in any of our ongoing personal projects.`}</p>
    <p>{`It also means there are possibly breaking changes coming for the component. We try to keep breaking changes at a minimum but sometimes
is just needed to fully have a good component for usage.`}</p>
    <iframe src="https://giphy.com/embed/PApUm1HPVYlDNLoMmr" width="480" height="333" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      